<template>
  <b-col>
    <!-- search input -->
    <!--Add Driver Button-->
    <b-col cols=" text-right">
      <b-button
        v-b-toggle.modal-2
        variant="danger"
        class="mb-1"
        @click="isEditingOrAdding = true"
      >{{ $t('add_driver') }}</b-button>
    </b-col>
    <!--End Add Driver Button-->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('search') }}</label>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('search_all_field')"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!--End search input -->

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="dir"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="$s3URL + 'uploads/user/' + props.row.pic"
            class="mx-1"
          />
          <span
            class="text-nowrap"
          >{{ props.row.first_name }} {{ props.row.last_name }}</span>
        </span>
        <b-col v-else-if="props.column.field === 'verified'">
          <b-button
            size="sm"
            block
            :variant="$driverStatus[props.row.verified].variant"
          >
            {{ $driverStatus[props.row.verified].name }}
          </b-button>
        </b-col>
        <!-- Column: NRC -->
        <b-col v-else-if="props.column.field === 'id'">
          <b-button
            v-b-toggle.modal-3
            block
            variant="dark"
            size="sm"
            @click="driverdetails(props.row)"
          ><FeatherIcon icon="Edit2Icon" /> {{ $t('edit') }}</b-button>
        </b-col>
        <!-- Column: License-->
        <b-col v-else-if="props.column.field === 'license'">
          <b-button
            v-if="props.row.license"
            block
            variant="success"
            size="sm"
            @click="showLicense(props.row.license)"
          >
            <FeatherIcon icon="CheckIcon" /> {{ $t('license_uploaded') }}</b-button>

          <b-button
            v-if="!props.row.license"
            variant="danger"
            block
            size="sm"
          >
            <FeatherIcon icon="XIcon" /> {{ $t('license_not_uploaded') }}</b-button>

        </b-col>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('showing') }} 1 {{ $t('to') }} </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> {{ $t('of') }} {{ props.total }} {{ $t('entries') }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Endtable -->

    <b-sidebar
      id="modal-2"
      ref="mySidebar"
      :bg-variant="currentSkin"
      :backdrop-variant="currentSkin"
      sidebar-class="sidebar-lg"
      backdrop
      right
      @hidden="isEditingOrAdding = false"
    >
      <div class="px-3">
        <validation-observer ref="addDriverForm">
          <b-form @submit.prevent>
            <h3 class="text-center">
              <b>{{ $t('add_driver') }}</b>
            </h3>
            <b-form-group
              :label="$t('first_name')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.first_name"
                  :placeholder="$t('first_name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('last_name')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.last_name"
                  :placeholder="$t('last_name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('phone_number')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  v-model="form.phone"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  round
                  name="Phone"
                  :placeholder="$t('phone_number')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('profile_picture')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Profile Picture"
                rules="required"
              >
                <b-form-file
                  ref="profile"
                  v-model="form.profile"
                  :placeholder="$t('profile_picture')"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="validate_profile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <label for="">{{ $t('drivers_license') }}<FeatherIcon
              size="20"
              class="hasCriteria"
              icon="InfoIcon"
              @click="showCriteria(1)"
            /></label>
            <!-- <b-input-group-prepend>

              </b-input-group-prepend> -->
            <validation-provider
              #default="{ errors }"
              name="License"
              rules="required"
            >
              <b-form-file
                ref="license"
                v-model="form.liscense"
                :placeholder="$t('license')"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="validate_license"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <div class="text-right mt-2">
              <b-button
                v-if="!show"
                block
                variant="primary"
                class="mr-1"
                @click="validationForm"
              >{{ $t('submit') }}</b-button>
              <b-button
                v-if="show"
                block
                variant="dark"
                class="mr-1"
              >{{ $t('please_wait') }}</b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>

    <b-sidebar
      id="modal-3"
      ref="mySidebarEdit"
      sidebar-class="sidebar-lg"
      right
      shadow
    >
      <div class="px-3">
        <validation-observer ref="EditDriverForm">
          <b-form @submit.prevent>
            <h3 class="text-center">
              <b>{{ $t('edit_driver') }}</b>
            </h3>
            <b-form-group
              :label="$t('first_name')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="editForm.first_name"
                  :placeholder="$t('first_name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('last_name')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="editForm.last_name"
                  :placeholder="$t('last_name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('phone_number')"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  v-model="editForm.phone"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  round
                  name="Phone"
                  :placeholder="$t('phone_number')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('new_profile_picture')"
              label-for="input-2"
            >
              <b-form-file
                ref="profile_edit"
                v-model="editForm.profileEdit"
                :placeholder="$t('new_profile_picture')"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="validate_profile_edit"
              />
            </b-form-group>
            <b-form-group
              :label="$t('new_license')"
              label-for="input-2"
            >
              <b-form-file
                ref="license_edit"
                v-model="editForm.licenseEdit"
                :placeholder="$t('new_license')"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="validate_license_edit"
              />
            </b-form-group>
            <div class="text-right mt-2">
              <b-button
                v-if="!show"
                block
                variant="primary"
                class="mr-1"
                @click="saveChanges"
              >{{ $t('save') }}</b-button>
              <b-button
                v-if="!show"
                block
                class="mr-1"
                style="background-color: #aa3738 !important"
                @click="handleDeleteDriver(editForm.id)"
              >{{ $t('delete_driver') }}</b-button>
              <b-button
                v-if="show"
                block
                variant="dark"
                class="mr-1"
              >{{ $t('please_wait') }}</b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>

    <b-modal
      id="critModal"
      ref="critLicenseModal"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      title="Document Criteria"
    >
      <DriverLicense />
    </b-modal>

    <b-modal
      id="critModal"
      ref="critNRICModal"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      title="Document Criteria"
    >
      <DriverNric />
    </b-modal>
  </b-col>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store/index'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// custom
import DriverLicense from '@/components/criteria/DriverLicense.vue'
import DriverNric from '@/components/criteria/DriverNric.vue'

export default {
  name: 'MyDriver',
  components: {
    DriverLicense,
    DriverNric,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },

  data() {
    return {
      required,
      orientation: store.state.appConfig.layout.isRTL,
      isEditingOrAdding: false,
      imagenric: null,
      imageurl: null,
      imageurls: null,
      currentProfile: null,
      currentLicense: null,
      currentNRIC: null,
      show: false,
      pageLength: 10,
      dir: false,
      editForm: {},
      form: {},
      columns: [
        {
          label: this.$t('driver'),
          field: 'name',
        },
        {
          label: this.$t('phone_number'),
          field: 'phone',
        },
        {
          label: this.$t('documents'),
          field: 'license',
        },
        {
          label: this.$t('status'),
          field: 'verified',
        },
        {
          label: this.$t('action'),
          field: 'id',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
      }
      return status => statusColor[status]
    },
    currentSkin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    orientation(val) {
      if (val) {
        this.dir = !this.dir
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  async created() {
    const base = `${this.$s3URL}uploads/user/imagenotavailable.png`
    this.imageurl = base
    this.imageurls = base
    this.imagenric = base
    await this.getDrivers()
  },
  methods: {
    accepted_format() {
      return 'image/png image/jpg image/jpeg'
    },
    validate_profile() {
      if (this.isEditingOrAdding) {
        const profile = this.form.profile.type
        const accepted = this.accepted_format().includes(profile)
        if (!accepted) {
          this.form.profile = null
          this.$refs.profile.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    validate_profile_edit() {
      if (this.isEditingOrAdding) {
        const profile = this.editForm.profileEdit.type ? this.editForm.profileEdit.type : null
        const accepted = this.accepted_format().includes(profile)
        if (!accepted) {
          this.editForm.profileEdit = null
          this.$refs.profile_edit.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    validate_license_edit() {
      if (this.isEditingOrAdding) {
        const license = this.editForm.licenseEdit ? this.editForm.licenseEdit.type : null
        const accepted = this.accepted_format().includes(license)
        if (!accepted) {
          this.editForm.licenseEdit = null
          this.$refs.license_edit.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    validate_nric_edit() {
      if (this.isEditingOrAdding) {
        const nric = this.editForm.nricEdit ? this.editForm.nricEdit.type : null
        const accepted = this.accepted_format().includes(nric)
        if (!accepted) {
          this.editForm.nricEdit = null
          this.$refs.nric_edit.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    validate_license() {
      if (this.isEditingOrAdding) {
        const license = this.form.liscense ? this.form.liscense.type : null
        const accepted = this.accepted_format().includes(license)
        if (!accepted) {
          this.form.liscense = null
          this.$refs.liscense.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    validate_nric() {
      if (this.isEditingOrAdding) {
        const nric = this.form.nric ? this.form.nric.type : null
        const accepted = this.accepted_format().includes(nric)
        if (!accepted) {
          this.form.nric = null
          this.$refs.nric.reset()
          this.showToast('PNG, JPG, JPEG')
        }
      }
    },
    showToast(accept) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: '',
          icon: 'CoffeeIcon',
          variant: 'info',
          text: `${this.$t('file_must_be')} ${accept} ${this.$('format')}`,
        },
      })
    },
    driverdetails(row) {
      this.editForm = { ...row }
      this.currentProfile = `${this.$s3URL}uploads/user/${this.editForm.pic}`
      this.currentLicense = `${this.$s3URL}uploads/user/${this.editForm.license}`
      this.currentNRIC = `${this.$s3URL}uploads/user/${this.editForm.nric}`
    },
    saveChanges() {
      const self = this
      this.$refs.EditDriverForm.validate().then(success => {
        if (success) {
          self.show = true
          const headers = {
            Accept: 'application/json',
          }

          const formData = new FormData()
          Object.entries(self.editForm).forEach(([key, value]) => {
            formData.append(key, value)
          })
          axios
            .post(`${this.$appURL}updatedriver`, formData, { headers })
            .catch(() => {})
            .then(response => {
              self.show = false
              if (response.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: '',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
              }
              self.isEditingOrAdding = false
              self.getDrivers()
              this.$refs.mySidebarEdit.hide()
              this.$nextTick(() => {
                self.editForm = {}
              })
            })
        }
      })
    },
    getDrivers() {
      const self = this
      const headers = {
        Accept: 'application/json',
      }
      axios
        .get(`${this.$appURL}drivers`, { headers })
        .catch(() => {})
        .then(response => {
          self.rows = response.data.data
          self.show = false
        })
    },
    showLicense(name) {
      window.open(
        `${this.$s3URL}uploads/user/${name}`,
        '_blank',
      )
    },
    validationForm() {
      const self = this
      this.$refs.addDriverForm.validate()
        .then(success => {
          if (success) {
            self.show = true
            const headers = {
              Accept: 'application/json',
            }

            const formData = new FormData()
            Object.entries(self.form).forEach(([key, value]) => {
              formData.append(key, value)
            })
            axios
              .post(`${this.$appURL}addRider`, formData, { headers })
              .catch(() => {})
              .then(response => {
                this.$refs.mySidebar.hide()
                self.show = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: '',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                self.getDrivers()
                self.isEditingOrAdding = false
                self.imagenric = null
                self.imageurl = null
                self.imageurls = null
                this.$nextTick(() => {
                  self.form = {}
                })
              })
          }
        }).catch(() => {})
    },
    showCriteria(docType) {
      if (docType === 1) return this.$refs.critLicenseModal.show()
      return this.$refs.critNRICModal.show()
    },
    closeCriteria(docType) {
      if (docType === 1) return this.$refs.critLicenseModal.hide()
      return this.$refs.critNRICModal.hide()
    },
    handleDeleteDriver(id) {
      this.$swal({
        title: this.$t('are_you_sure'),
        text: this.$t('remove_this_driver'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yes_remove_driver'),
        cancelButtonText: this.$t('cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`/drivers/${id}`, { _method: 'DELETE' }).then(response => {
            if (response.status === 200) {
              const index = this.rows.findIndex(x => x.id === id)
              this.rows.splice(index, 1)
              this.$swal({
                icon: 'success',
                title: this.$t('removed'),
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.mySidebarEdit.hide()
              this.$nextTick(() => {
                this.editForm = {}
              })
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" >
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .hasCriteria{
    cursor: pointer !important;
  }
</style>
