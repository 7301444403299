<template>
      <b-card-body>
          <b-row>
            <b-col cols=6>
              <b-img :src="require('@/assets/images/guide_nric.png')" fluid alt="Fluid-grow image" />
            </b-col>
            <b-col cols=6>
                <h4>Accepted document criteria:</h4> 
                <ul>
                  <li>Kindly cross your IC on the top right side and write the purpose as “SMARTTRUCK Registration”.</li>
                  <li>Document file must be in JPEG or PDF format.</li>
                  <li>Identification Card owner may sign or no sign on bottom right is acceptable.</li>
                  <li>Visible with high resolution. Blur document / Image will be not accepted.</li>
                  <li>Normal card size with center alignment of Front and Back side in one A4 paper size.</li>
                  <li>White plain background.</li>
                  <li>Recommended file size below 300KB. Compress file if file size exceed more than 300KB.</li>
                  <li>Recommended file uploaded in the colour version.</li>
                </ul>
            </b-col>
          </b-row>
    </b-card-body>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BImg
} from 'bootstrap-vue'

export default {
    components:{
        BImg,
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody
    }
}
</script>
